import React from "react"
import "../css/Footer.css"

import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
const Footer = () => {
  return (
    <section className="footer" id="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>

          <div className="col-lg-10 col-md-12 contact">
            <div className="row footer-row">
              <div className="col-lg-5 col-md-6 col-sm-12 contact-border">
                <div className="row contact-title">
                  <span>Contact Details</span>
                </div>

                {/* <div className="row contact-icons">
                  <span className="fa-icon">
                    <FaPhoneAlt />
                  </span>
                  <span className="number">&nbsp; +1 (917) 583-6393</span>
                </div> */}

                <div className="row contact-icons">
                  <span className="fa-icon">
                    <FaEnvelope />
                  </span>
                  <span className="number">
                    &nbsp; start@succeedlearn.com
                  </span>
                </div>
              </div>

              <div className="col-lg-7 col-md-6 col-sm-12 social">
                <div className="row contact-title">
                  <span>Get Social With Us</span>
                </div>

                <div className="row social-icons">
                  <a
                    href="https://www.facebook.com/succeedlearn/"
                    className="social-links"
                  >
                    <img
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606389819/succeedlearn/wwteanzj0yoaqgjtpqm0.png"
                      alt="facebook"
                    ></img>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    href="https://www.linkedin.com/company/succeedlearn/"
                    className="social-links"
                  >
                    <img
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606389819/succeedlearn/amy9mg7hdzrzi8mkiufu.png"
                      alt="linkedin"
                    ></img>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    href="https://service.ariba.com/Discovery.aw/ad/profile?key=AN01489481791#"
                    className="social-links"
                  >
                    <img
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606389819/succeedlearn/ns0prm31au7lxxsdl203.png"
                      alt="ariba"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row copyright-row py-lg-3">
          <div className="col-md-12 text-center">
            <span className="copyright text-white ">
              Copyright © {new Date().getFullYear()} SucceedLEARN.com Powered By
              Succeed Technologies®
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
