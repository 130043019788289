import React from "react"
import { Link } from "gatsby"
import { BsCaretDownFill } from "react-icons/bs"

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap"
import Headroom from "react-headroom"
import Logo from "../components/Navbar/Logo"
import "../css/Navbar.css"
const Header = props => {
  let logo = props.logo
  let favicon = props.favicon

  return (
    <>
      <Headroom>
        <div className="container-fluid  nav_bg">
          <div className="row">
            <div className="col-12 mx-auto">
              <nav
                className="navbar navbar-expand-lg  navbar-light"
                sticky="top"
              >
                <div className="container-fluid child ">
                  <Link className="navbar-brand" to="/">
                    {/* {logo.map(({ node }) => (
                      <img
                        src={
                          node.acf.logo_image_.localFile.childImageSharp.fluid
                            .originalImg
                        }
                        alt="succeedlearn"
                      />
                    ))} */}
                    {/* {logo.map(({ node }) => (
                      <img
                        src={
                          node.acf.logo_image_.localFile.childImageSharp.fluid
                            .originalImg
                        }
                        alt="SucceedLearn"
                      />
                    ))} */}

                    <picture>
                      <source
                        media="(max-width: 650px)"
                        srcset="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-238-1.png"
                      />
                      <source
                        media="(max-width: 465px)"
                        srcset="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-238-1.png"
                      />
                      <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-238-1.png" />
                    </picture>
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <div className="dropdown">
                          <button className="dropbtn nav-link">
                            Courses <BsCaretDownFill />
                          </button>

                          <div className="dropdown-content">
                            <Link
                              className="nav-link "
                              aria-current="page"
                              to="/Infosec-data-protection"
                            >
                              Security & Privacy Awareness
                            </Link>
                            <Link
                              className="nav-link "
                              aria-current="page"
                              to="/Harassment-discrimination-courses"
                            >
                              Discrimination & Harassment Prevention
                            </Link>

                            <Link
                              className="nav-link "
                              aria-current="page"
                              to="/financial-compliance/"
                            >
                              Financial Crime Prevention
                            </Link>

                            <Link
                              className="nav-link "
                              aria-current="page"
                              to="/health-and-safety-courses/"
                            >
                              Health & Safety
                            </Link>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/succeedlms">
                          Succeed LMS
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link "
                          aria-current="page"
                          to="/blogs"
                        >
                          Blogs
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link "
                          aria-current="page"
                          to="/aboutus"
                        >
                          About Us
                        </Link>
                      </li>

                      <li className="nav-item" style={{ "margin-top": "5px" }}>
                        <Link
                          to="/contact"
                          style={{
                            "padding-top": "15px",
                          }}
                          className="schedule-demo-menu"
                        >
                          <button
                            type="button"
                            className="btn btn-primary btn-sm schedule-demo"
                          >
                            Schedule a Demo
                          </button>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </Headroom>
    </>
  )
}

export default Header
